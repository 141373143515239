<template>
  <v-row class="statuses">
    <v-col cols="3" v-if="loading">
      <div class="statuses__headline">
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
            class="ml-4"
        />
      </div>
      <div class="statuses__items">
        <v-skeleton-loader
            type="list-item"
            width="100%"
            height="100%"
            v-for="i in 7"
            :key="i"
        />
      </div>
    </v-col>
    <v-col cols="3" v-if="!loading">
      <div class="statuses__headline">
        <h3>Теги</h3>
        <v-btn text icon @click="createNewItem" v-if="hasRight('tags.manage')">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-text-field
          label="Поиск"
          class="evi-text-field mt-5 mr-3"
          color="#44D370"
          v-model="search"
          clearable
      >
        <template v-slot:append>
          <img class="mt-1" width="15" :src="require('@/assets/img/analytics/search.svg')" alt="">
        </template>
      </v-text-field>
      <div class="tags__items">
        <div class="tags__item" @click="selectItem(item)" v-for="item in filteredItems" :key="item.id">
                <span class="title-name" :style="{backgroundColor: `${item.color}`, color: `${item.text_color} !important` }">
                    {{ item.name }}
                </span>
        </div>
      </div>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col class="ml-5" v-if="loading">
      <div class="statuses__headline mb-2">
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
        />
      </div>
      <div class="statuses__fields">
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
            class="mb-7"
        />
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
            class="mb-7"
        />
        <v-skeleton-loader
            type="card, actions"
            width="350"
            height="100%"
        />
      </div>
    </v-col>
    <v-col class="ml-5" v-if="!loading && tags.length">
      <div class="statuses__headline mb-2">
        <h4 class="mt-1">ТЕГ — <span class="tag" :style="{backgroundColor: `${selectedItem.color}`, color: `${selectedItem.text_color}` }">{{ selectedItem.name }}</span></h4>
        <v-btn class="evi-delete-button" @click="deleteItemModal = true" color="#ffffff" height="59"
               v-if="hasRight('tags.manage')">
          Удалить тег
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
      <div class="statuses__fields">
        <StatusForm :selectedStatus="selectedItem" @reset="reset" @saveStatus="saveItem"/>
      </div>
      <v-dialog
          v-model="deleteItemModal"
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Удаление тега
          </v-card-title>
          <v-card-text>Вы действительно хотите удалить тег <span
              class="font-weight-bold">"{{ selectedItem.name }}"</span>?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="deleteItemModal = false"
            >
              Отмена
            </v-btn>
            <v-btn
                color="red"
                text
                @click="deleteTagsItem"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import StatusForm from "../../components/tags/StatusForm";
import {hasRight} from '@/utils/access/hasRight';

export default {
  name: "Tags",
  components: {StatusForm},
  data: () => ({
    localItems: '',
    selectedItem: {},
    deleteItemModal: false,
    search: '',
  }),
  computed: {
    ...mapState("tags", ["tags",]),
    ...mapState("common", ["loading"]),
    filteredItems() {
      if (!this.search) {
        return this.localItems;
      }
      return this.localItems.filter(i => {
        return i.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      })
    },
  },
  methods: {
    ...mapActions({
      getItems: 'tags/getItems',
      createItem: 'tags/createItem',
      deleteItem: 'tags/deleteItem',
      getSelectedItem: 'tags/getSelectedItem',
      saveSelectedItem: 'tags/saveSelectedItem',
    }),
    hasRight: hasRight,
    updateItems() {
      this.localItems = this.tags;
    },
    selectItem(item) {
      if (item) {
        this.getSelectedItem(item.id).then((response) => {
          this.selectedItem = response;
        })
      }
    },
    reset() {
      this.selectItem(this.selectedItem);
    },
    saveItem(item) {
      this.saveSelectedItem(item);
    },
    itemsInit() {
      this.getItems().then(() => {
        this.updateItems();
        if (!this.selectedItem.name) {
          this.selectItem(this.localItems[0]);
        }
      });
    },
    createNewItem() {
      this.createItem().then(() => {
        this.itemsInit();
      })
    },
    deleteTagsItem() {
      this.deleteItem(this.selectedItem).then(() => {
        this.selectedItem = {};
        this.itemsInit();
      });
      this.deleteItemModal = false;
    }
  },
  mounted() {
    this.itemsInit();
  },

}
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 2px;
  padding: 7px;
  background: #1C624A;
}
</style>
